import { useCallback, useState } from "react";
import { usePageSearch } from "@ludens-reklame/rubics-v3-react/page/hooks";
import { useStore } from "@ludens-reklame/rubics-v3-react/store/hooks";
import { CardGrid } from "../../internal-components/cardGrid/cardGrid.js";
import { CardVariant, PageCard } from "../../internal-components/card/card.js";
import { ChipList, ChipSelect } from "../../internal-components/chip/chip.js";

interface Props {
  variant?: CardVariant;
  tags?: string[];
}

const PagesSearch: React.FC<Props> = ({ variant, tags = [] }) => {
  const [selectedPages, setSelectedPages] = useState<string[]>([]);
  const context = useStore().context;
  const page = context.page;
  const children = context.children;
  const { results, hasMore, search } = usePageSearch(
    { fetchOnInit: true },
    { descendantOf: page._id }
  );

  const handleSelectPage = useCallback(
    (pageId: string, selected: boolean) => {
      let newSelectedPages: string[] = [];

      if (selected && !selectedPages.includes(pageId)) {
        newSelectedPages = [...selectedPages, pageId];
      } else if (!selected) {
        newSelectedPages = selectedPages.filter((id) => id !== pageId);
      }

      setSelectedPages(newSelectedPages);
      search(
        false,
        tags.length > 0
          ? { tags: newSelectedPages }
          : { childOf: newSelectedPages }
      );
    },
    [selectedPages, search, tags]
  );

  return (
    <div className="vs-xxl hs mw">
      <div className="vs-l filter-actions">
        <ChipList>
          {tags.length === 0 &&
            children.map((page) => (
              <ChipSelect
                key={page._id}
                label={page.title}
                isSelected={selectedPages.includes(page._id)}
                onSelect={(e) => {
                  const { checked } = e.target;
                  handleSelectPage(page._id, checked);
                }}
              />
            ))}
          {tags.length > 0 &&
            tags.map((tag) => (
              <ChipSelect
                key={tag}
                label={tag}
                isSelected={selectedPages.includes(tag)}
                onSelect={(e) => {
                  const { checked } = e.target;
                  handleSelectPage(tag, checked);
                }}
              />
            ))}
        </ChipList>
      </div>
      <CardGrid>
        {results.map((page) => (
          <PageCard
            key={page._id}
            page={page}
            titleType="h2"
            variant={variant}
          />
        ))}
      </CardGrid>
      {hasMore && (
        <div className="vs-xl h-center">
          <button onClick={() => search(true)}>Last inn flere</button>
        </div>
      )}
    </div>
  );
};

export default PagesSearch;
